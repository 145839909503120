import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  __name: 'Stats',
  props: {
    likes: Number,
    liked: Boolean,
    comments: Number,
    views: Object,
    duration: String,
    realNumbers: Boolean,
    modelId: String,
    modelName: String
  },
  setup: function setup(__props) {
    var props = __props;
    var Like = function Like() {
      return import('~/components/reactions/Like.vue');
    };
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n,
      $store = _useNuxtApp.$store;
    var usersListStore = useUsersListStore();
    var likable = computed(function () {
      return props.modelId && props.modelName;
    });
    var getTitle = function getTitle() {
      var title = '';
      if (!props.views) return title;
      var _props$views = props.views,
        feedCounter = _props$views.feedCounter,
        totalCounter = _props$views.totalCounter,
        postCounter = _props$views.postCounter;
      if (feedCounter && totalCounter) {
        title = $i18n.t('views.feedTitle', {
          feedCounter: feedCounter,
          totalCounter: totalCounter
        });
      }
      if (postCounter && feedCounter && totalCounter) {
        title = $i18n.t('views.postTitle', {
          postCounter: postCounter,
          feedCounter: feedCounter,
          totalCounter: totalCounter
        });
      }
      return title;
    };
    function showLikes() {
      usersListStore.update({
        id: "likes-".concat(props.modelId),
        url: '/user/getlikes',
        title: 'likes',
        params: {
          model: props.modelName,
          id: props.modelId
        }
      });
    }
    return {
      __sfc: true,
      Like: Like,
      $i18n: $i18n,
      $store: $store,
      usersListStore: usersListStore,
      props: props,
      likable: likable,
      getTitle: getTitle,
      showLikes: showLikes
    };
  }
};