<i18n>
{
    "uk": {
        "draftRestore": "Відновити в чернетки",
        "publishRestore": "Відновити в опубліковані",
        "publishRestoreHelp": "Кейс буде відновлено і повернено в відкритий доступ",
        "restoreError": "Не вдалось відновити кейс.",
        "confirmDestroy": "Після остаточного видалення цей матеріал буде не можливо відновити. Видалити кейс «{title}»?",
        "confirmRemove": "Після видалення, кейс зникне і буде недоступний для всіх користувачів — відновити його можна з кошика. Видалити кейс «{title}»?"
    },
    "en": {
        "draftRestore": "Restore To Draft",
        "publishRestore": "Restore & Publish",
        "publishRestoreHelp": "The case will be restored and public",
        "restoreError": "Failed to restore case.",
        "confirmDestroy": "After the final deletion, this item will be impossible to restore. Delete the case \"{title}\"?",
        "confirmRemove": "After deletion, the case will disappear and will be unavailable to all users - you can restore it from the bin. Delete case \"{title}\"?"
    }
}
</i18n>

<template>
    <Babka
        class="thumbnail-case"
        :title="post.title || $t('general.untitled')"
        :link="!noLink ? `/case/${post.slug || post.id}` : null"
        :image="post.thumbnail && post.thumbnail.name ? `${$CDN}/image/standart/${post.thumbnail.name}` : ''"
        :class="{'grayscale': post.status != 'published'}"
        :useWebp="true"
        :target="target"
    >
        <template v-slot:image>
            <slot />
        </template>

        <template v-slot:imageOverlay>
            <div class="awards" v-if="post.awards">
                <div
                    v-for="awardItem in post.awards"
                    :key="`${post.id}-${awardItem.id}`"
                    class="award"
                >
                    <img
                        :src="awardItem.award.label"
                        :alt="awardItem.award.name"
                        :title="awardItem.award.name"
                        width="24"
                        height="36"
                        class="d-block"
                    >
                </div>
            </div>
        </template>

        <template v-slot:inMiddle>
            <panel-button 
                v-if="isOwner && post.status == 'published'"
                class="post-boost btn-bg fw-700"
                name="boost-post"
            >
                <span class="icon-24 icon-ic-rocket" /> {{$t('general.boost')}}
            </panel-button>

            <dd-button
                v-if="canEdit"
                :id="`post-actions-${post.id}`"
                :name="`post-actions-${post.id}`"
                class="btn-bg post-actions icon-ic-dots"
            />
        </template>

        <div class="d-flex flex-column flex-grow-1">
            <nuxt-link
                v-profile="{
                    modelName: authorType,
                    modelId: author.id,
                    placement: 'top'
                }"
                class="d-block author elevate profile-shadow"
                :class="{round: authorType == 'user'}"
                :to="localePath(authorLink)"
            >
                <WebpPicture
                    imgClass="image-block"
                    :src="authorPic"
                    width="40" height="40"
                    :alt="author.displayName || author.name"
                    loading="lazy"
                    decoding="async"
                />
            </nuxt-link>


            <div class="mt-1 f-small color-gray-0 credits">
                {{post.displayPerfomers}}
                <span v-if="post.displayClients">
                    {{ $t('general.for') }} {{post.displayClients}}
                </span>
            </div>

            <div class="d-flex mt-1 align-items-center mb-3">
                <nuxt-link
                    @click.native="$emit('selectSpecialization', {event: $event, id: post.specialization.id})"
                    v-if="post.specialization"
                    class="tag gray linked"
                    :to="localePath(`/cases?specialization=${post.specialization.id}`)"
                >
                    {{post.specialization.locales[$i18n.locale]}}
                </nuxt-link>
            </div>

            <Stats
                class="mt-auto"
                :class="{'ml-n3': post.status == 'published' && post.id}"

                :likes="post.likes"
                :comments="post.comments"
                :views="post.views"

                :liked="post.liked"
                :modelId="post.id"
                :modelName="post.status == 'published' ? 'publication' : null"

                @update-likes="(e) => post.likes = e"
                @update-liked="(e) => post.liked = e"
            />


            <client-only>
                <Dropdown
                    v-if="canEdit"
                    :modal-name="`post-actions-${post.id}`"
                    :anchor-id="`post-actions-${post.id}`"
                    placement="bottom-end"
                >
                    <ul class="dd-list p-1">
                        <li>
                            <nuxt-link
                                menu-item
                                class="menu-item iconed"
                                :to="localePath(`/editor/${post.id}`)"
                            >
                                <span class="icon icon-ic-pencil-outline" />
                                {{$t('general.edit')}}
                            </nuxt-link>
                        </li>
                        <li v-if="post.status == 'published'">
                            <button
                                menu-item
                                class="menu-item iconed"
                                @click="hide"
                            >
                                <span class="icon icon-ic-eye-strike" />
                                {{$t('general.hide')}}
                            </button>
                        </li>

                        <li v-if="post.status == 'removed'">
                            <button
                                menu-item
                                class="menu-item iconed"
                                @click="hide"
                            >
                                <span class="icon icon-ic-restore"/>
                                {{ $t('draftRestore') }}
                            </button>
                        </li>

                        <li v-if="post.status == 'removed' && post.originalStatus == 'published'">
                            <button
                                menu-item
                                class="menu-item iconed align-items-start ta-left"
                                @click="restore"
                            >
                                <span class="icon-ic-paper-airplane-outline icon"/>
                                <div>
                                    {{ $t('publishRestore') }}
                                    <div class="f-small mt-h color-gray-0">
                                        {{ $t('publishRestoreHelp') }}
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li class="delimiter-bold my-1"></li>
                        <li>
                            <button
                                menu-item
                                class="menu-item iconed r-link"
                                @click="remove"
                            >
                                <span class="icon icon-ic-bin" />
                                {{$t('general.remove')}}
                            </button>
                        </li>
                    </ul>
                </Dropdown>
            </client-only>

        </div>
    </Babka>
</template>

<script>
import WebpPicture from '~/components/WebpPicture.vue';
import Stats       from '~/components/reactions/Stats.vue';
import Babka       from '~/components/thumbnails/BabkaThumbnail.vue';

export default {
    components: {
        Babka,
        Stats,
        WebpPicture,
        DdButton:    () => import('~/components/DdButton.vue'),
        Dropdown:    () => import('~/components/CasesDropdown.vue'),
        PanelButton: () => import('~/components/panels/PanelButton.vue'),
        
    },

    props: ['post', 'noLink', 'disableActions', 'target'],

    computed: {
        author () {
            return this.post.company ? this.post.company : this.post.author;
        },

        authorType () {
            return this.post.company ? 'company' : 'user'
        },

        authorPic () {
            return this.author.logo || this.author.photo;
        },

        authorLink () {
            return `/${this.author.slug || this.author.id}`;
        },

        isOwner () {
            let isOwner = false;
            
            if (!this.$auth.user || this.disableActions) return isOwner;

            if (this.authorType == 'user' && this.author.id == this.$auth.user.id) {
                isOwner = true;
            }

            if (this.authorType == 'company' && this.$auth.user.moders) {
                isOwner = this.$auth.user.moders.some(o => {
                    return o.company.id == this.author.id && o.approved;
                });
            }
            return isOwner;
        },

        canEdit () {
            if (!this.$auth.user || this.disableActions) return false;

            return this.isOwner || this.$auth.user.role.fullAccess;
        }
    },

    methods: {
        remove () {
            if (this.post.status == 'removed') {
                let title = this.post.title || this.$t('general.untitled')
                if (!window.confirm(this.$t('confirmDestroy', {title}))) return;
            }

            if (this.post.status == 'published') {
                let title = this.post.title || this.$t('general.untitled')
                if (!window.confirm(this.$t('confirmRemove', {title}))) return;
            }

            this.$axios.post(`/publication/destroy/${this.post.id}`)
            .then(res => {
                this.$emit('removed');
            })
            .catch(err => {
                console.log(err);
                this.$toastStore.add({
                    title: this.$t('case.deleteErrorTitle'),
                    text: this.$t('case.deleteErrorText'),
                    icon: 'icon-ic-dismiss'
                });
            })

        },

        hide () {
            if (this.post.status == 'published') {
                let title = this.post.title || this.$t('general.untitled')
                if (!window.confirm(this.$t('case.confirmHide', {title}))) return;
            }

            this.$axios.post(`/publication/draft/${this.post.id}`)
            .then(res => {
                this.$emit('hidden');
            })
            .catch(err => {
                console.log(err);
                this.$toastStore.add({
                    title: this.$t('case.hideErrorTitle'),
                    text: this.$t('case.hideErrorText'),
                    icon: 'icon-ic-dismiss'
                });
            })
        },

        restore () {
            if (this.post.originalStatus !== 'published') return true;

            this.$axios.$post('/publication/publish', {id: this.post.id})
            .then(res => {
                this.$emit('hidden');
            })
            .catch(err => {
                console.log(err);
                this.$toastStore.add({
                    title: this.$t('restoreError'),
                    text: this.$t('case.hideErrorText'),
                    icon: 'icon-ic-dismiss'
                });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.award {
    width: 24px;
    position: relative;

    img {
        width: 24px;
        height: 36px;
    }
}

.post-actions {
    position: absolute;
    z-index: 5;
    top: 16px;
    right: 16px;

    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.post-boost {
    position: absolute;
    z-index: 5;
    top: 12px;
    left: 16px;
    height: 32px;
    padding: 4px 12px 4px 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    background-color: var(--color-cases-blue);

    &:hover {background-color: #44A2E9;}
    &:active {background-color: #156EB2 !important;}
}

@media screen and (min-width: 576px) {
    .dd-list {width: 328px;}
}
</style>
