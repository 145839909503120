<template>
    <Card class="thumbnail-babka grid-item no-pad">
        <div
            v-if="badge"
            class="badge ff-monospace"
        >
            {{badge}}
        </div>

        <slot name="image">
            <Component
                v-if="link || clickable"
                :is="clickableComponent"
                :href="link ? link : null"
                :to="link && target != '_blank' ? localePath(link) : null"

                class="image"
                :target="link ? target : null"
                :rel="link ? rel : null"
                @click="handleClick"
            >
                <img
                    :src="img"
                    :alt="title"
                    loading="lazy"
                    width="100%"
                    v-if="!useWebp || !image || ext == 'gif'"
                    decoding="async"
                >

                <picture v-else>
                    <source :srcset="`${img}.webp`" type="image/webp">
                    <source :srcset="img" :type="imgType">
                    <img :src="img" :alt="title" loading="lazy" width="100%" decoding="async">
                </picture>

                <slot name="imageOverlay" />
            </Component>

            <div v-else class="image">
                <img :src="img" :alt="title" loading="lazy">
                <slot name="imageOverlay" />
            </div>
        </slot>

        <slot name="inMiddle" />

        <div class="body">
            <div class="body-inner">
                <Component
                    v-if="link || clickable"
                    :is="clickableComponent"
                    :href="link && target == '_blank' ? link : null"
                    :to="link && target != '_blank' ? localePath(link) : null"

                    class="title d-link"
                    :target="link ? target : null"
                    :rel="link ? rel : null"
                    :title="title"
                    @click="handleClick"
                >
                    {{title}}
                </Component>
                <div v-else class="title">{{title}}</div>
                <slot />
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '~/components/cards/Card.vue';

export default {
    components: {
        Card
    },

    props: {
        title: String,
        link: String,
        image: String,
        badge: String,
        clickable: {
            type: Boolean,
            default: false,
        },
        useWebp: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
            default: '_self',
        }
    },

    computed: {
        img () {
            return this.image || '/images/c-placeholder-full.png'
        },

        rel () {
            return this.target == '_blank' ? 'noopener' : ''
        },

        ext () {
            if (!this.image) return null;
            let split = this.image.split('.');
            return split[split.length - 1].toLowerCase()
        },

        imgType () {
            if (!this.ext) return true;
            let type = this.ext == 'jpg' ? 'jpeg' : this.ext;
            return `image/${type}`;
        },

        clickableComponent () {
            if (this.link) {
                if (this.target == '_blank') return 'a';
                return 'nuxt-link';
            }

            return  'button';
        }
    },

    methods: {
        handleClick () {
            if (this.link) return true;
            if (this.clickable) this.$emit('click');
        }
    },
}
</script>
